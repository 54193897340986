import React from "react"
import { FormattedMessage } from "react-intl"
import MASS from "./mass"
import ReactNavbar from "./navbar"

import { Jumbotron, Container } from "reactstrap"

import "./header.css"

const Header = () => (
  <div>
    <ReactNavbar />
    <Jumbotron className="home-jumbotron" fluid>
      <Container fluid className="index-margin">
        {/* <h1 className="mass-intro">
                <FormattedMessage id="org.massnet.components.header.mass" />
              </h1> */}
        <MASS />
        <p className="mass-desc-index">
          <FormattedMessage id="org.massnet.components.header.mass_desc" />
        </p>
        <a
          href="https://download.massnet.org/research/MASS-A%20Blockchain%20Consensus%20Engine.pdf"
          download=""
          target="_blank"
          className="btn-download-wp-white"
        >
          <FormattedMessage id="org.massnet.components.research.whitepaper.download" />
        </a>
      </Container>
    </Jumbotron>
  </div>
)

export default Header
