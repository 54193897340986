import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"

import "./development-details.css"

const DevelopmentDetails = () => (
  <Fragment>
    <div className="index-develop-bg">
      <div className="container" id="develop-index">
        <h1 className="development-details-title">
          <span>
            <FormattedMessage id="org.massnet.components.development.title" />
          </span>
        </h1>
        <div className="development-details-heading">
          <span style={{marginRight: "127px"}}>
            <FormattedMessage id="org.massnet.components.development.heading.stage" />
          </span>
          <span style={{marginRight: "550px"}}>
            <FormattedMessage id="org.massnet.components.development.heading.content" />
          </span>
          <span style={{textAlign: "right"}}>
            <FormattedMessage id="org.massnet.components.development.heading.time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage development-details-stage-line">
            <FormattedMessage id="org.massnet.components.development.stage.first_stage" />
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.first_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.first_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage">
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.second_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.second_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage development-details-stage-line">
            <FormattedMessage id="org.massnet.components.development.stage.second_stage" />
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.third_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.third_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage">
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.forth_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.forth_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage">
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.fifth_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.fifth_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage">
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.sixth_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.sixth_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage">
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.seventh_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.seventh_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage development-details-stage-line">
            <FormattedMessage id="org.massnet.components.development.stage.third_stage" />
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.eighth_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.eighth_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage development-details-stage-line">
            <FormattedMessage id="org.massnet.components.development.stage.forth_stage" />
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.ninth_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.ninth_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage development-details-stage-line">
            <FormattedMessage id="org.massnet.components.development.stage.fifth_stage" />
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.tenth_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.tenth_time" />
          </span>
        </div>
        <div className="development-param">
          <span className="development-details-stage development-details-stage-line">
            <FormattedMessage id="org.massnet.components.development.stage.sixth_stage" />
          </span>
          <span className="development-details-content">
            <FormattedMessage id="org.massnet.components.development.eleventh_process" />
          </span>
          <span className="development-details-time">
            <FormattedMessage id="org.massnet.components.development.eleventh_time" />
          </span>
        </div>
      </div>
    </div>
  </Fragment>
)

export default DevelopmentDetails
