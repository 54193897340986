import React from "react"
import {
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardGroup,
  CardBody,
} from "reactstrap"
import { FormattedMessage } from "react-intl"
import { StaticQuery, graphql } from "gatsby"

import "./feature.css"

const Feature = () => (
  <StaticQuery
    query={graphql`
      query {
        permissionFreeImage: file(
          relativePath: { eq: "feature-permission-free.svg" }
        ) {
          publicURL
        }
        fairImage: file(relativePath: { eq: "feature-fair.svg" }) {
          publicURL
        }
        greenImage: file(relativePath: { eq: "feature-green.svg" }) {
          publicURL
        }
        universalImage: file(relativePath: { eq: "feature-universal.svg" }) {
          publicURL
        }
        secureImage: file(relativePath: { eq: "feature-secure.svg" }) {
          publicURL
        }
      }
    `}
    render={data => (
      <CardGroup className="feature-group container">
        <Card>
          <div className="card-inner br">
            <CardImg
              top
              width="40"
              height="40"
              src={data.permissionFreeImage.publicURL}
            />
            <CardBody>
              <CardTitle>
                <FormattedMessage id="org.massnet.components.feature.permission_free" />
              </CardTitle>
              <CardText>
                <FormattedMessage id="org.massnet.components.feature.permission_free_desc" />
              </CardText>
            </CardBody>
          </div>
        </Card>
        <Card>
          <div className="card-inner br">
            <CardImg
              top
              width="40"
              height="40"
              src={data.fairImage.publicURL}
            />
            <CardBody>
              <CardTitle>
                <FormattedMessage id="org.massnet.components.feature.fair" />
              </CardTitle>
              <CardText>
                <FormattedMessage id="org.massnet.components.feature.fair_desc" />
              </CardText>
            </CardBody>
          </div>
        </Card>
        <Card>
          <div className="card-inner br">
            <CardImg
              top
              width="40"
              height="40"
              src={data.greenImage.publicURL}
            />
            <CardBody>
              <CardTitle>
                <FormattedMessage id="org.massnet.components.feature.green" />
              </CardTitle>
              <CardText>
                <FormattedMessage id="org.massnet.components.feature.green_desc" />
              </CardText>
            </CardBody>
          </div>
        </Card>
        <Card>
          <div className="card-inner br">
            <CardImg
              top
              width="40"
              height="40"
              src={data.universalImage.publicURL}
            />
            <CardBody>
              <CardTitle>
                <FormattedMessage id="org.massnet.components.feature.universal" />
              </CardTitle>
              <CardText>
                <FormattedMessage id="org.massnet.components.feature.universal_desc" />
              </CardText>
            </CardBody>
          </div>
        </Card>
        <Card>
          <div className="card-inner">
            <CardImg
              top
              width="40"
              height="40"
              src={data.secureImage.publicURL}
            />
            <CardBody>
              <CardTitle>
                <FormattedMessage id="org.massnet.components.feature.secure" />
              </CardTitle>
              <CardText>
                <FormattedMessage id="org.massnet.components.feature.secure_desc" />
              </CardText>
            </CardBody>
          </div>
        </Card>
      </CardGroup>
    )}
  />
)

export default Feature
